
import * as React from "react";
import {
  Wrapper,
  PdpWrapper,
  DescriptionWrapper,
ArtistDescription,
  Citations,
  PhotosWrapper,
  CardWrapper,
  ProfilWrapper,
  NameWrapper,
  CarrouselWrapper2,
  LinkWrapper,
  ButtonWrapper,
  TitleWrapper,
  ArtistInfos,
  ImageWrapper,
  MobileProtrait,
  MoreButton,
  More,
} from "./EddieMartinez.module.css";
import { Helmet } from "react-helmet";

import Title from "./../../../../components/Title";
import Carousel from 'react-multi-carousel';
import EddieMartinezPhoto1 from "../../../../res/Photos site/Eddie Martinez/eddie-martinez-prof-2-3-crop_webp-555x312.webp"
import EddieMartinezPhoto2 from "../../../../res/Photos site/Eddie Martinez/Eddie-Martinez-in-his-Studio-luxe-net.jpg.webp"
import EddieMartinezPhoto3 from "../../../../res/Photos site/Eddie Martinez/3.jpg"
import EddieMartinezPhoto4 from "../../../../res/Photos site/Eddie Martinez/4.jpg"
import EddieMartinezPhoto5 from "../../../../res/Photos site/Eddie Martinez/5.jpg"
import Button from "./../../../../components/Button";
import Pdp from '../../../../res/martinez/portrait.jpg'
const isBrowser = typeof window !== "undefined";
const infos ={
  path: "EddieMartinez",
  name: "EddieMartinez",
  description:"Eddie Martinez est un artiste américain né en 1977 à Naval, Connecticut qui vit et travaille à Brooklyn. Il a acquis une reconnaissance internationale grâce à son utilisation de lignes énergiques et de manipulations de couleurs vives dans des combinaisons très contrastées en peinture comme en sculpture. Son expression particulière révèle sa passion pour l’histoire de l’art et pour les mouvements historiques de la peinture tels que le Cobra, le néo-expressionnisme et l’Action-painting mêlée à son expérience personnelle, son goût pour les cultures populaires et son regard sur le paysage contemporain. Le travail d’Eddie Martinez a fait l’objet d'expositions personnelles dans de nombreuses institutions, dont le Bronx Museum of the Arts, le Drawing Center à New York ou encore le Davis Museum à Wellesley, dans le Massachusetts. Ses oeuvres figurent dans de nombreuses collections publiques et privées telles que la Saatchi Collection et la Hiscox Collection à Londres; la Colección Jumex à Mexico; la Marciano Collection à Los Angeles; le Morgan Library & Museum à New York; et le Davis Museum à Wellesley. ",
  pdp: Pdp,
  alt_pdp: "Photo de profil de EddieMartinez.",
  photos: [
    { src: EddieMartinezPhoto1, name: "EddieMartinez" },
    { src: EddieMartinezPhoto3, name: "EddieMartinez" },
    { src: EddieMartinezPhoto4, name: "EddieMartinez" },
  ],
  works: ["Works", "Works", "Works"],
  videos: ["Videos", "Videos", "Videos"],
  citations: []
};

const EddieMartinez = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
      <Helmet>
        {
            isBrowser ? window.onscroll = function(e){
              var sel = document.querySelector('#f')
              var end = document.querySelector('#end').offsetTop - 450;
              if (window.scrollY > end) {
                  sel.style.position = 'absolute';
                  sel.style.top = end.toString()+ "px";
              } else {
                  sel.style.position = 'fixed';
                  sel.style.top = 0;
              }
            } : null
          }
      </Helmet>
      <div className={CardWrapper}>
        <div className={ProfilWrapper} id="f">
          <img className={PdpWrapper} src={infos.pdp} alt={infos.alt_pdp} />
        </div>
        <div className={ArtistDescription}>
          <h1 className={TitleWrapper}>EDDY MARTINEZ <h2 style={{paddingLeft: "16px"}}>(Américain)</h2></h1>
          <h3 className={NameWrapper} >Né en 1977</h3>
          <img className={MobileProtrait} src={infos.pdp} alt={infos.alt_pdp} />
          <div className={DescriptionWrapper} dangerouslySetInnerHTML={{__html:infos.description}}></div>
          <div  className={Citations}>
            {infos.citations.map((citations) => (
              <div>
                <p style={{fontStyle: "italic", textAlign: "center" }}>{citations}</p>
                <br />
              </div>
            ))}
          </div>
          <br />
        <ul className={ArtistInfos}>
        <br />
          <p style={{fontWeight: "bolder"}}>SOLO EXHIBITIONS</p>
          <br />
          <p style={{fontWeight: "bold"}}>2022</p>
          <p>- Eddie Martinez : Pigeon Sweet, Blum & Poe, Los Angeles, California, USA, January 15th - February 26th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2021</p>
          <p>- Fingers pointing at the moon, Galerie Perrotin, Shanghai, November 5th - December 25th.</p>
          <p>- Green Thumb, Blum & Poe, Los Angeles, California, USA, May 15th - June 26th.</p>
          <p>- New Paintings II, Loyal Gallery, Stokolm, Sweden, March 20th - MAy 15th.</p>
          <p>- Inside Thoughts, Michel-innes & Nash Gallery, NY, USA, January 21th - February 27th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2020</p>
          <p>- Eddie Martinez : Open Feast, Yuz Museum, West Bund, Shanghai, China, November 7th, 2019 - January 12th, 2020.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2019</p>
          <p>- EMHK19, Perrotin, Hong Kong, May 17th - June 29th.</p>
          <p>- Fast Eddie: New Paintings, Museum of Contemporary Art Detroit, USA, May 10th - August 18th.</p>
          <p>- Blockhead Stacks, Perrotin, Tokyo, Japan, December 20th, 2018 - January 23th, 2019.</p>
          <p>- White Outs, The Bronx Museum, New York, USA, November 14th, 2018 - February 17th, 2019.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2018</p>
          <p>- Love Letters and Yard Work, Mitchell-Innes & Nash, New York, USA, January 18th - February 24th.</p>
          <p>- Studio Wall, The Drawing Center, New York, USA, October 13th, 2017 - January 21th, 2018.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2017</p>
          <p>- Ants at a Picknic, Davis Museum at Wellesley College, Wellesley, USA, September 19th - December 17th.</p>
          <p>- Cowboy Town, Timothy Taylor, London, UK, March 30th - May 6th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2016</p>
          <p>- Stop the world and let me off, Sorry We're Closed, Brussels, Belgium, April 19th - May 21th.</p>
          <p>- Salmon Eye, Mitchell-Innes & Nash, New York, USA, January 30th - March 5th.</p>
          <div className={More} id="more">
          <br />
          <p style={{fontWeight: "bold"}}>2014</p>
          <p>- Island I, Timothy Taylor, London, UK, October 13th - November 8th.</p>
          <p>- Nomader, Kohn Gallery, Los Angeles, USA, September 12th - October 25th.</p>
          <p>- Neanderthal Jeans, Half Gallery, New York, USA, June 5th - July 15th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2013</p>
          <p>- Beginner Mind, Bill Brady KC, Kansas City, USA, March 29th - May 11th.</p>
          <p>- Matador Eddie Martinez, The Journal Gallery, New York, USA, March 8th - April 28th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2012</p>
          <p>- Studio Drawings, Half Gallery, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2011</p>
          <p>- Seeker Eddie Martinez, Peres Projects, Berlin, Germany, November 11th - December 17th.</p>
          <p>- Eddie Martinez, New York Electric, Patricia Low Contemporary, Geneva, Switzerland, September 16th - November 11th.</p>
          <p>- Eddie Martinez – Drawings, 2005-2011, Schwarz Contemporary, Berlin, Germany, March 24th</p>
          <p>- May 7th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2010</p>
          <p>- The Feast, presented by ZieherSmith, Art Positions, Art Basel Miami Beach, Miami, USA.</p>
          <p>- Buffet State Of Mind, Sorry We’re Closed, Brussels, Belgium, November 12th - May 21th.</p>
          <p>- Eddie Martinez, Zieher Smith, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2009</p>
          <p>- News and Updates, Galerie Mikael Andersen, Copenhagen, Denmark, September 17th - October 17th.</p>
          <p>- Eddie Martinez, Seomi & Tuus, Seoul, South Korea.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2008</p>
          <p>- Some Drawings, The Journal Gallery, New York, USA.</p>
          <p>- Eddie Martinez, Zieher Smith, New York, USA.</p>
          <p>- New Paintings, Loyal Gallery, Stockholm, Sweden, May 30th - July 6th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2007</p>
          <p>- Hooters, Galería Comercial, San Juan, Puerto Rico.</p>
          <p>- Darkness, Aliceday, Brussels, Belgium, February 2nd - March 17th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2006</p>
          <p>- Eddie Martinez, ZieherSmith, Volta Show, Basel, Switzerland.</p>
          <p>- A Horse With No Name, ZieherSmith, New York, USA.</p>
          <p>- A Mini Carrot Experience, Loyal Gallery, Stockholm, Sweden, January 27th - March 4th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2005</p>
          <p>- Abracadabra, Bucket Rider, Chicago, USA, December 9th, 2005 - January 14th, 2006.</p>
          <p>- A Big Ole Steely Dan Conversation, Allston Skirt Gallery, Boston, USA.</p>
          <p>- Cool Beans Brother, Loyal Gallery, Stockholm, Sweden, April 8th - May 22th.</p>
          <br />
          <p style={{fontWeight: "bolder"}}>GROUP EXHIBITIONS</p>
          <br />
          <p style={{fontWeight: "bold"}}>2022</p>
          <p>- Kings County Tennis League, Benefit Auction 2022, 26 Aout - 12 Septembre 2022.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2021</p>
          <p>- Friends and Family, Magenta Plains, NY, USA, October 30th - December 18th.</p>
          <p>- Works on paper, Rodolphe Janssen Gallery, Brussels, Belgium, June 10th - July 10th.</p>
          <p>- Friend Zone, Half Gallery, NY, USA, January 28th - February 24th.</p>
          <p>- Nos Fantômes - exposition inaugurale, Ghost galerie, Paris, France, 10 Septembre - 20 Novembre, 2021.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2020</p>
          <p>- Kaleidoscopes : Contemporary Portraiture, Perrotin, Hong Kong, China, June 20th - August 8th.</p>
          <p>- Sam Moyer & Eddie Martinez : Arthur, Ratio 3, San Francisco, USA, June 16th - August 19th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2019</p>
          <p>- Senior Coconut : Eddie Martinez, Gerasimos Floratos, Rafael Delacruz, Reyes Finn, Detroit, USA, March 15th - May 11th.</p>
          <p>- JAYWALKERS: Eddie Martinez, Odessa Straub, Rafael Delacruz, LOYAL Gallery, Stockholm, Sweden, January 31th - March 16th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2018</p>
          <p>- Palo Santo, Ratio 3, San Francisco, USA, July 13th - August 18th.</p>
          <p>- A New Way of Walking, Timothy Taylor, London, United Kingdom, June 7th - August 24th.</p>
          <p>- Beyond the Streets, Los Angeles, USA, July 17th - August 28th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2017</p>
          <p>- 10th Anniversary, Gallery Target, Tokyo, Japan, October 19th - November 4th.</p>
          <p>- Drawing Island, The Journal Gallery, Brooklyn, USA, February 16th - March 27th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2016</p>
          <p>- 13, V1 Gallery, Copenhagen, Denmark, November 27th, 2016 - January 4th, 2017.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2015</p>
          <p>- The Avant-Garde Won't Give Up: COBRA and Its Legacy, Blum & Poe, Los Angeles, USA, September 9th - October 17th.</p>
          <p>- New York Painting, Kunstmuseum Bonn, Bonn, Germany, May 14th - August 30th.</p>
          <p>- Die Dritte Hand - Last Exit Painting, Salon Dahlmann, Berlin, Germany, January 24th - March 7th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2014</p>
          <p>- Brushcrusher, Studio 353, New York, USA.</p>
          <p>- Vanitas: Contemporary Reflections on Love and Death from the Collection of Stephane Janssen, Phoenix Art Museum, Phoenix, USA, November 2nd - February 8th.</p>
          <p>- Life, The Journal Gallery at Venus Over Manhattan, New York, USA, May 28th - July 25th.</p>
          <p>- Tip Top, Greenpoint Terminal Gallery, Brooklyn, USA, February 15th - March 8th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2013</p>
          <p>- Body Language, Saatchi Gallery, London, United Kingdom, November 20th, 2013 - March 16th, 2014.</p>
          <p>- Paint On, Paint Off, Halsey McKay Gallery, New York, USA, November 16th - December 8th.</p>
          <p>- Drawing Gym, organised by Brian Belott, 247365 & Know More Games, New York, USA, September 13th - October 9th.</p>
          <p></p>
          <p>- LAT. 41° 7' N., LONG. 72° 19' W, Martos Gallery, East Marion, New York, USA, July 13th - September 2nd.</p>
          <p>- Work, Klaus Von Nichtssagend Gallery, New York, USA, June 27th - July 27th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2012</p>
          <p>- EDDIE MARTINEZ – Paintings / PABLO PICASSO – Ceramics, Sorry We're Closed, Art Brussels, Brussels, Belgium, April 19th - Avril 22th.</p>
          <p>- NOLAN HENDRICKSON, EDDIE MARTINEZ, JP MUNRO, Zach Feuer Gallery, New York, USA, December 1st - January 12th.</p>
          <p>- Wonders – Masterpieces from Private Collections in Denmark, KUNSTEN Museum of Modern Art, Aalborg, Denmark, June 9th - September 2nd.</p>
          <p>- Eddie Martinez & Jose Lerma, Halsey McKay Gallery, East Hampton, USA, August 11th - August 29th.</p>
          <p>- Eagles, Marlborough Madrid, Spain, October 25th - November 24th.</p>
          <p>- Breed, Greenberg Van Doren, New York, USA, July 11th - August 24th.</p>
          <p>- Portrait of a Generation, The Hole, New York, USA, June 7th - August 10th.</p>
          <p>- New Prints 2012/Winter, International Print Center New York, USA, January 28th - March 24th.</p>
          <p>- Thanksgiving, V1 Gallery, Copenhagen, Denmark, January 13th - February 4th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2011</p>
          <p>- BNA, ZieherSmith Pop-Up Gallery, Nashville, USA, August 6th - 27th.</p>
          <p>- Back room, Untitled, New York, USA.</p>
          <p>- Facemaker, curated by Kathy Grayson, Royal/T, Culver City, USA, April 1st - June 30th.</p>
          <p>- The Big Clown Show, Sorry We’re Closed, Art Brussels, Brussels, Belgium, April 28th - May 1st.</p>
          <p>- New York Minute, Garage Center For Contemporary Culture, Moscow, Russia, April 23th - June 5th.</p>
          <p>- Triangle Fool, Hiromiyoshii Roppongi Gallery, Tokyo, Japan, April 1st - April 30th.</p>
          <p>- I Am Who I Am: The Portrait Reconsidered, Steven Zevitas Gallery, Boston, USA, January 20th - February 26th.</p>
          <p>- Cover Version LP, curated by Timothy Hull, BAMart, Brooklyn, USA, January 11th - March 20th.</p>
          <p>- Group Show, Galerie Suzanne Tarasieve, Paris, France, May 13th - July 30th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2010</p>
          <p>- The Power of Selection, curated by Ryan Travis Christian, Western Exhibitions, Chicago, USA, November 19th - December 31th.</p>
          <p>- Not Quite Open for Business, The Hole, New York, USA.</p>
          <p>- Insider/Outsider, Portugal Arte 2010, Lisbon, Portugal, July 16th - August 15th.</p>
          <p>- Draw, curated by Erik Foss and Curse Mackey, Museo de la Ciudad de Mexico, Mexico City, Mexico, June 19th - August 15th.</p>
          <p>- Wall to Wall, Daniel Weinberg Gallery, Los Angeles, USA, June 5th - August 14th.</p>
          <p>- Apartment Show Presents Big Apple, Clifton Benevento, New York, USA, March 26th - November 4th.</p>
          <p>- Forth Estate Editions, Frederieke Taylor Gallery, New York, USA.</p>
          <p>- The Cannibal’s Muse, curated by Max Henry, Patricia Low Gallery, Gstaad, Switzerland, June 19th - August 8th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2009</p>
          <p>- Forth Estate Editions, RISD Memorial Hall Gallery, Providence, USA.</p>
          <p>- New York Minute, MACRO FUTURE, Museum of Contemporary Art, Rome, Italy, September 20th - November 1st.</p>
          <p>- Grand Reopening, ZieherSmith, New York, USA.</p>
          <p></p>
          <p>- Leaves of Ash, Suzanne Tarasieve Gallery, Paris, France, June 25th - July 25th.</p>
          <p>- Just Landed, Wes Lang & Eddie Martinez, Aliceday, Brussels, Belgium, April 25th - May 23th.</p>
          <p>- Collaborations, ZieherSmith, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2008</p>
          <p>- This is Boston, Not LA, LaMontagne Gallery, Boston, USA.</p>
          <p>- Whatever is Whatever, curated by Dimitrios Antonitsis, Hydra Project, Hydra, Greece.</p>
          <p>- Timeless, Morris Museum, Morristown, USA.</p>
          <p>- Mail Order Monsters, curated by Kathy Grayson, Andreas Melas Presents, Athens, Greece; and Max Wigram Gallery, London, United Kingdom.</p>
          <p>- Living In A Box, Galerie Mikael Andersen, Berlin, Germany, March 14th - April 26th.</p>
          <p>- Ambivalent figuration; people, Samson Projects, Boston, USA, May 9th - July 26th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2007</p>
          <p>- Eddie Martinez, William J. O’Brian, Roman Wolgin, Jonas Wood, Gallery Min Min, Tokyo, Japan, November 2nd - December 1st.</p>
          <p>- Accessories, Galerie Mikael Andersen, Copenhagen, Denmark.</p>
          <p>- Painters, Cosar HMT, Düsseldorf, Germany.</p>
          <p>- Mail Order Monsters, curated by Kathy Grayson, Deitch Projects, New York; and Peres Projects, Berlin, Germany, July 1st - August 4th</p>
          <p>- Athens Biennial, curated by Dan Nadel, Athens, Greece.</p>
          <p>- Cabinet of Curiosities, curated by Kathy Grayson, The Journal, Brooklyn, USA, September 14th - October 4th.</p>
          <p>- So Wrong, I’m Right, Blum & Poe, Los Angeles, USA, June 30th - August 25th.</p>
          <p>- Crystal Crunch, curated by Taylor McKimens, Perugi, Padua, Italy.</p>
          <p>- Five Painters, Loyal Gallery, Stockholm, Sweden, January 19th - March 3rd.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2006</p>
          <p>- Panic Room: Works from the Dakis Joannou Collection, Deste Foundation Centre for Contemporary Art, Athens, Greece, February 2 nd - April 4th.</p>
          <p>- Beyond, curated by Caroline Hwang & Suzannah Sinclair, New Image Art, Los Angeles, USA, December 2nd - January 13th.</p>
          <p>- You Have to be Almost Gifted to do What I do, Alexander & Bonin, New York, USA, May 27th - July 28th.</p>
          <p>- Don’t Abandon The Ship, Allston Skirt Gallery, Boston, USA.</p>
          <p>- Fine Line, Adam Baumgold Gallery, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2005</p>
          <p>- Thank You for the Days, Bucket Rider Gallery, Chicago, USA.</p>
          <p>- Winners, HiFi Gallery, Austin, USA.</p>
          <p>- Greater Brooklyn, CRG Gallery, New York, USA.</p>
          <p>- Loyal and his Band, hand drawn covers, Loyal Gallery, Stockholm, Sweden, September 9th - September 18th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2004</p>
          <p>- Eddie Martinez and Denise Kupferschmidt, Evos Arts, Lowell, USA.</p>
          <p>- In The Making, KCDC, Brooklyn, USA.</p>
          <p>- The 18th Drawing Show, Mills Gallery, Boston, USA.</p>
          <p>- Eddie Martinez and Matt Clark, Compound Gallery, Portland, Orlando, USA.</p>
          <p>- Suburban, 1300 Gallery, Cleveland, Ohio, USA.</p>
          <p>- The Russian Doll Show, 65 Hope Street Gallery, Brooklyn, USA; New Image Art, Los Angeles, USA; Space 1026, Philadelphia, USA; and Green Street Gallery, Jamaica Plain, Massachusetts, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2003</p>
          <p>- Dishwasher Safe, Wurst Gallery, Portland, Oregon, USA.</p>
          <p>- Halloween Show, Evos Arts, Lowell, Massachusetts, USA.</p>
          <p>- Go Team, Upstairs At Johnsons, Boston, USA.</p>
          <p>- Textile, Somerville Arts Center, Somerville, Massachusetts, USA.</p>
          <p>- Subconscious, 1300 Gallery, Cleveland, Ohio, USA.</p>
          <p>- Overhung, Oni Gallery, Boston, USA.</p>
          <p>- Animal Show, Massachusetts College of Art, Boston, USA.</p>
          <br />
          <p style={{fontWeight: "bolder"}}>PRIVATE COLLECTIONS</p>
          <br />
          <p>- Bronx Museum of the Arts, New York, NY, USA.</p>
          <p>- Modern Art Museum of Fort Worth, Fort Worth, TX, USA.</p>
          <p>- Davis Museum at Wellesley College, Wellesley, MA, USA.</p>
          <p>- Marciano Collection.</p>
          <p>- Morgan Library & Museum, New York, USA.</p>
          <p>- Rhode Island School of Design Museum, Providence, USA.</p>
          <p>- Saatchi Collection, London, UK.</p>
          <p>- Hiscox Collection, London, UK.</p>
          <p id="end">- Museo Jumex, Mexico city, Mexico.</p>
          </div>
        </ul>
            <button className={MoreButton} id="moreBtn" onClick={() => {
                 var sel = document.querySelector('#more');
                 var selBtn = document.querySelector('#moreBtn');
                 console.log("in there");

                
                  if (sel.style.display === "none") {
                    sel.style.display = "inline";
                    selBtn.innerHTML = "Read more";
                  } else {
                    sel.style.display = "none";
                    selBtn.innerHTML = "Read less";
                }
                 
              }}
               >Read more</button>
        </div>
      </div>
      

      <div className={PhotosWrapper}>
       <Title title={"Past or Present"} />
        <div className={CarrouselWrapper2}>
          {/* <Carrousel images={infos.photos} />
           */}
           <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
        </div>
      </div>
      <div className={ButtonWrapper}>
            <Button
              alt=""
              onClick={() => {
                if (typeof window !== "undefined") {
                  window.location.href = "/fr/contactez-nous";
                }
              }
            }
            >
              Contactez-nous
            </Button>
        </div>
    </div>
  );
};

export default EddieMartinez;