// extracted by mini-css-extract-plugin
export var ArtistDescription = "EddieMartinez-module--ArtistDescription--6vvTO";
export var ArtistInfos = "EddieMartinez-module--ArtistInfos--H2MlS";
export var ButtonWrapper = "EddieMartinez-module--ButtonWrapper --UQ+zW";
export var CardWrapper = "EddieMartinez-module--CardWrapper--xThxl";
export var CarrouselWrapper2 = "EddieMartinez-module--CarrouselWrapper2--7JjIj";
export var Citations = "EddieMartinez-module--Citations--52oFY";
export var DescriptionWrapper = "EddieMartinez-module--DescriptionWrapper--5j+1Y";
export var ImageWrapper = "EddieMartinez-module--ImageWrapper--4Hj9S";
export var LinkWrapper = "EddieMartinez-module--LinkWrapper--dIVE5";
export var MobileProtrait = "EddieMartinez-module--MobileProtrait--zSLP8";
export var More = "EddieMartinez-module--More--Eb3I1";
export var MoreButton = "EddieMartinez-module--MoreButton--rnuDj";
export var NameWrapper = "EddieMartinez-module--NameWrapper--PXyyd";
export var PdpWrapper = "EddieMartinez-module--PdpWrapper--pzoaW";
export var PhotosWrapper = "EddieMartinez-module--PhotosWrapper--SVFIK";
export var ProfilWrapper = "EddieMartinez-module--ProfilWrapper--NGvCE";
export var TitleWrapper = "EddieMartinez-module--TitleWrapper--iighY";
export var Wrapper = "EddieMartinez-module--Wrapper--KBB0O";